import { Component } from "react"
import React from 'react';

import Navbar from "./navbar"
import Footer from "./footer";
import '../styles/services.css';

import { FaArrowAltCircleRight } from "react-icons/fa"



export default class Services extends Component {

    state = {
        restaurantRead: false,
    };

    // componentDidMount () {
    //     this.setState({ restaurantRead: this.restaurantRead }, () => {
    //         console.log(this.state.restaurantRead, 'restaurantRead1');
    //       });
    // }

    // readMore = () => {

    //     setReadMoreShown(prevState => !prevState)
    //     // this.setState({ restaurantRead: true }, () => {
    //     //     console.log(this.state.restaurantRead, 'restaurantRead1');
    //     // });
    // }

    render() {
        return (
            <div className="services-container">
                <Navbar />
                <div className="card-container">

                    <div className="header-container">
                        <header className="services-header">
                            <h1>Services</h1>
                        </header>
                        <div className="quick-links">
                            <h2>Quick Scroll</h2>
                            <a href="#hood_cleaning">Restaurant Hood Cleaners <FaArrowAltCircleRight /></a>
                            <a href="#reliable_laundry_duct_cleaning">Laundry Duct Cleaning <FaArrowAltCircleRight /></a>
                            <a href="#fire_cleanup">Fire Cleanup <FaArrowAltCircleRight /></a>
                        </div>

                    </div>
                    <div style={{ overflow: "hidden" }}>
                        <svg
                            preserveAspectRatio="none"
                            viewBox="0 0 1200 120"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ fill: '#ffffff', width: '125%', height: 75, }}
                        >

                            <path
                                d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
                                opacity=".25"
                            />
                            <path
                                d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
                                opacity=".5"
                            />
                            <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />
                        </svg>
                        <div id="hood_cleaning" className="card-row">

                            <div className="card">
                                <img className="card-image" src="../../images/before_afters/IMG_0594.jpg" alt="Kitchen" />
                                <h3 >Restaurant Hood Cleaning</h3>

                            </div>
                            <section className="description">
                                <header>
                                    <h2>Reliable Restaurant Hood Cleaners</h2>
                                </header>
                                <div className="request-section">
                                    <a className="services" href="/request-service/">Request Service</a>

                                </div>
                                <article>

                                    <p>&emsp; Your kitchen generates a lot of heat from stovetops, ovens, and fryers running every day. Call the experts at Nevada Exhaust Cleaning to provide complete cleaning, inspection, and repair services for your full hood and duct system.</p>
                                    <p>&emsp; We'll make sure that the heat from the kitchen has a safe exit with a clean, fully functioning restaurant hood exhaust system. You can trust our over 30 years' experience to provide you with great services and protect you from potential fire hazards. Your satisfaction is guaranteed!</p>

                                    <header>
                                        <h3>Experts You Can Trust</h3>
                                    </header>
                                    <p>&emsp; Nevada Exhaust Cleaning is a locally owned and operated business that was started in 1989. We have been serving both small and large businesses with exceptional exhaust cleaning services. Whether it's a small family restaurant or one of the largest casinos in Nevada, we welcome accounts of all sizes.</p>
                                    <h3>Long-Lasting Safety for Years to Come</h3>
                                    <p>&emsp; Don't compromise with your kitchen's safety and call the experts at Nevada Exhaust Cleaning. We will clean the hood duct in your kitchen, inspect the exhaust fans, and ensure your clients and employees are well protected. Trust only the best in the area to keep your restaurant safe. Call us today!</p>
                                    <header>
                                        <h3>24-Hour Emergency Service</h3>
                                    </header>
                                    <p>&emsp; Accidents may happen anytime in your kitchen. Depend on us to get it cleaned up and back in order in no time. Nevada Exhaust Cleaning provides 24-hour emergency service. All the work is guaranteed too.</p>
                                    <p>&emsp; We are certified with the Nevada State Fire Marshal and NFPA 96. Give us a call today to schedule a service and protect your business from fire hazards. Our technicians are fluent in English. Se habla español.</p>

                                    <header>
                                        <h3 id="fire_cleanup">Fire Cleanup Services</h3>
                                    </header>
                                    <p>&emsp; Get fast, affordable, and reliable appliance cleanup services today. Keeping your restaurant hood exhaust systems clean is a strong defense against fire hazards. It also helps to bring more business. Call us to get FREE estimates. We provide estimates the next business day.</p>

                                </article>
                                <div className="phone">
                                    <h5>Call now for free estimate:</h5>
                                    <a href="tel:775-355-9915">(775) 355-9915</a>
                                </div>
                                <div className="divider">
                                    {/* border bottom */}
                                </div>
                            </section>

                        </div>

                        <div id="reliable_laundry_duct_cleaning" className="card-row">

                            <div className="card">
                                <img className="card-image" src="../../images/before_afters/IMG_0589.jpg" alt="Kitchen" />
                                <h3>Commercial Laundry Duct</h3>
                            </div>
                            <section className="description">
                                <header>
                                    <h2>Reliable Laundry Duct Cleaning</h2>
                                </header>
                                <div className="request-section">
                                    <a className="services" href="/request-service/">Request Service</a>
                                </div>
                                <article>
                                    <p>&emsp; Get complete duct cleaning services that comply with all local fire codes and regulations by certified technicians at Nevada Exhaust Cleaning. We are certified with the Nevada State Fire Marshal and NFPA 96. All our cleaning services are backed by a full guarantee.
                                        Whether you run a full-size laundromat, have full laundering services for your hotel's linens, or have a small washing operation for your restaurant's napkins and linens, we've got you covered. We will help you in fire protection too.
                                        Contact us to get a FREE estimate. We have the most competitive rates available in the area.</p>
                                    <header>
                                        <h3>Fast, Affordable, and Reliable</h3>
                                    </header>
                                    <p>&emsp; You can count on Nevada Exhaust Cleaning to keep your laundry clean and safe. We understand your business operations are highly demanding. Call the experts to get the duct cleaning you need. We'll get back to you by the next business day. Get top-quality workmanship with every single service.</p>
                                    <header>
                                        <h3>Ensure the Smooth Running of Your Business</h3>
                                    </header>
                                    <p>&emsp; Prevent problems before they disable your commercial laundry operations. Rely on Nevada Exhaust Cleaning for top-quality duct cleaning services. Our certified professionals will complete a full inspection to make sure everything is operating safely and smoothly. Call us today to learn more!</p>
                                    <header>
                                        <h3>Let Us Help You With Your Commercial Laundry Duct</h3>
                                    </header>
                                    <p>&emsp; Did you notice a lint fire occur within your laundry ducts? Don't waste any time and give our professionals a call immediately. Breathe easy as the certified professionals at Nevada Exhaust Cleaning will visit your location and help clean up the damage. Get 24-hour emergency service. We return your calls and emails the next business day.</p>
                                </article>
                                <div className="phone">
                                    <h5>Call now for free estimate:</h5>
                                    <a href="tel:775-355-9915">(775) 355-9915</a>
                                </div>

                                <div className="divider">
                                    {/* border bottom */}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }


}