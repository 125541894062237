import React from "react";
import emailjs from "@emailjs/browser"
import Swal from "sweetalert2"

import Navbar from "./navbar"
import Footer from "./footer"
import "../styles/apply.css";



export default class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendStatus: "Send"
        };

        // const cookies = new Cookies();
        // cookies.set(key1, value1, { secure: true, sameSite: 'none' });
        // cookies.set(key2, value2, { secure: true, sameSite: 'none' });
    }

    sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6j67ki6', 'template_tmtu8sh', e.target, 'user_WpLvYUKh59KgaFazMjV56')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
        this.showAlert()

    };

    // emailjs.sendForm('service_6j67ki6', 'template_tmtu8sh', this);


    showAlert = () => {
        Swal.fire({
            title: 'Message Sent',
            text: "Your message was successfully sent to Nevada Exhaust Cleaning!",
            type: 'success',
        })
    }

    render() {
        return (

            <div className="request-service-page">

                <Navbar />
                <div className="contact-box">
                    <div className="service-headers">
                        <header>
                            <h2 data-shadow='dang!'>Work with us!</h2>
                        </header>
                        <article>
                            <h4>Please fill out the application bellow. <br />Make sure attatch your resume at the bottom, or copy and paste your resume in the message section.</h4>
                        </article>
                        <a href="tel:775-355-9915">(775) 355-9915</a>

                    </div>
                    <section className="box">

                        <form id="myForm" encType="multipart/form-data" method="post" onSubmit={this.sendEmail}>
                            <div className="top-inputs">
                                <div className="input-container">
                                    <input className="input" placeholder="Name" type="text" name="from_name" />
                                </div>
                                <div className="input-container">
                                    <input className="input" placeholder="Phone" type="text" name="phone" />
                                </div>
                                <div className="input-container">
                                    <input className="input" placeholder="Email" type="email" name="email" />
                                </div>


                            </div>

                            <div className="apply-bottom-inputs">
                                <textarea placeholder="Copy and Paste Resume or attach a file below..." name="message" />
{/* FIXME TODO: on iphone simulators, choose file is out of range in styles.
        I need to research some input attributes as css doesn't seem to do much with it.
*/}
                                <input className="choose-file" type="file" name="my_file" accept=".jpg, .png, .pdf, .jpeg" />
                                <button className="button" type="submit" >Send</button>
                            </div>

                        </form>

                    </section>

                </div>
                <Footer />
            </div>

        );
    }
}