import { Component } from "react"
import React from 'react';
import { NavLink } from "react-router-dom";


import Navbar from "./navbar";
import Footer from "./footer";
import "../styles/about.css"

import { FaCcMastercard, FaCcDiscover, FaCcVisa, FaCcAmex } from "react-icons/fa"



export default class About extends Component {

    state = {
        isLoading: true,
    };

    render() {
        return (

            <div className="about-container">

                <Navbar />
                <div className="about-page-container">
                    {/* <div className="owner-header-container">
                        <div className="about-card-container">
                            <img className="about-card-image" src="../images/owner.JPG" alt="owner" />
                            <h3>Jay Taylor</h3>
                        </div>
                        <div className="owner-discription">
                            <h3>Owner</h3>
                            <p>This is the owner yada yada</p>
                        </div>

                    </div> */}
                    <section className="about-square-section">
                        <div className="about-square-row">
                            <div className="blue-text-square">
                                <header>
                                    <h3>About Nevada Exhaust Cleaning</h3>
                                </header>
                                <article>
                                    <p>Nevada Exhaust Cleaning provides restaurant hood cleaning, commercial laundry duct cleaning, and commercial appliance cleaning to Nevada and northern California.</p>
                                </article>
                            </div>
                            <div className="white-text-square">
                                <header>
                                    <h3>Year Established</h3>
                                </header>
                                <h4>1989</h4>
                            </div>
                        </div>
                        <div className="about-square-row">

                            <div className="white-text-square">
                                <header>
                                    <h3>Specialties</h3>
                                </header>
                                <p>Commercial duct cleaning
                                    Hood fan cleaning</p>
                            </div>
                            <div className="blue-text-square">
                                <header>
                                    <h3 className="payment-header">Payment Types</h3>
                                </header>
                                <div className="left-payment">
                                    <FaCcVisa />
                                    <FaCcDiscover />
                                </div>
                                <div className="right-payment">
                                    <FaCcMastercard />
                                    <FaCcAmex />
                                </div>

                            </div>
                        </div>
                        <div className="about-square-row">
                            <div className="blue-text-square">
                                <header>
                                    <h3>Services</h3>
                                </header>
                                <p>Commercial appliance cleaning
                                    Commercial laundry duct cleaning
                                    Fire cleanup Restaurant hood cleaning</p>
                            </div>
                            <div className="white-text-square">
                                <header>
                                    <h3>Languages</h3>
                                </header>
                                <h4>English</h4>
                                <h4>Spanish</h4>
                            </div>
                        </div>
                    </section>
                    <div className="request-section">
                        <h3>Need to request a service?</h3>
                        <NavLink className="services" to="/request-service">Request Service</NavLink>

                    </div>
                </div>
                <Footer />
            </div>
        )
    }


}