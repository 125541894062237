import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { Text, TrackballControls } from '@react-three/drei';
import { useRef, useState, useMemo, useEffect } from 'react'


function Word({ children, ...props }) {
    const color = new THREE.Color();
    const fontProps = { fontSize: 2.8, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false };
    const ref = useRef();
    const [hovered, setHovered] = useState(false);
    // eslint-disable-next-line no-sequences
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(true);

    useEffect(() => {
      if (hovered) document.body.style.cursor = 'pointer';
      return () => (document.body.style.cursor = 'auto');
    }, [hovered]);

    useFrame(({ camera }) => {
      ref.current.lookAt(camera.position);
      ref.current.material.color.lerp(color.set(hovered ? '#fa2720' : 'white'), 0.1);
    });

    return <Text ref={ref} onPointerOver={over} onPointerOut={out} onClick={() => console.log('clicked')} {...props} {...fontProps} children={children} />;
  }


  function Cloud({ count = 21, radius = 20 }) {
    const cloudRef = useRef();

    useEffect(() => {
      const cloudGroup = cloudRef.current;
      cloudGroup.rotation.y = Math.PI / 2; // Start with an initial rotation if desired
      const animateRotation = () => {
        cloudGroup.rotation.y += 0.0015; // Adjust the rotation speed as needed
        requestAnimationFrame(animateRotation);
      };
      animateRotation();
    }, []);

    const words = useMemo(() => {
      const temp = [];
      // const spherical = new THREE.Spherical();
      const phiSpan = Math.PI * (3 - Math.sqrt(5));
      // const thetaSpan = 2 * Math.PI / count;

      for (let i = 0; i < count; i++) {
        const y = 1 - (i / (count - 1)) * 2; // Adjusted y calculation
        const radiusOffset = Math.sqrt(1 - y * y);
        const phi = i * phiSpan;

        const x = Math.cos(phi) * radiusOffset * radius;
        const z = Math.sin(phi) * radiusOffset * radius;
        const accounts = [
          "International\nAirports",
          "Burger King",
          "McDonald's",
          "Wendys",
          "Taco Bell",
          "Arby’s",
          "Round Table\nPizza",
          "Little Caesar\n\t Pizza",
          "Walmart",
          "Flying J",
          "Safeways\n\t Markets",
          "Smiths Market",
          "Mt Rose\n\t Ski Resort",
          "Palisades Tahoe\n\t Ski Resort",
          "Incline Ski\n\t Resort",
          "Best Western\n\t Hotels",
          "Marriott Hotels",
          "Court Yard\n\t Hotels",
          "Jersey Mikes",
          "Blue Kangaroo\n\t Child Care",
          "Brookdale\n\t Retirement Home",
          "Brookdale\n\t Memory Care",
          "Atlantis Resort\n\t and Casino",
            "Grand Sierra\n\t Resort",
            "Nugget Sparks",
            "Legends Bay\n\t Casino",
            "Fandango Casino",
            "Washoe County\n\t Jail",
            "Washoe County\n\t Retirement Home",
            "Humboldt\n\t Hospitals",
            "Lyon County \n\tHospital",
            "Mt Grant\n\t Hospital",
            "Renown\n\t Hospitals",
            "Lyon County\n\t Schools",
            "Carson City\n\t Schools",
            "Churchill\n\t county schools",
            "South Lake\n\t Tahoe Schools",
            "Truckee Tahoe\n\t Schools",
            "VA Hospital",
            "Fallon Navel\n\t Air Base",
            "Summit Fire\n\t & Security ",
            "Commercial Fire",
            "AAA Fire",
            "Delta Fire",
        ];

        const randomIndex = Math.floor(Math.random() * accounts.length);
        const word = accounts[randomIndex];

        temp.push([new THREE.Vector3(x, y * radius, z), word]);
      }

      return temp;
    }, [count, radius]);

    return (
      <group ref={cloudRef}>
        {words.map(([pos, word], index) => (
          <Word key={index} position={pos} children={word} />
        ))}
      </group>
    );
  }
export default function App() {
  return (
    <Canvas dpr={[1, 2]} camera={{ position: [0, 0, 35], fov: 90 }}>
      <pointLight position={[10, 10, 10]} />
      <fog attach="fog" args={['#003366', 0, 20]} />
      <Cloud count={44} radius={20} />
      <TrackballControls noRotate={false} noZoom={true} noPan={false} />
    </Canvas>

  );
}