import React from "react";
import { NavLink } from "react-router-dom";

export default class Hamburger extends React.Component {
    //this.stuff = this.stuff.bind(this);
    render() {
        return (
            <nav role="navigation">
                <div className="menuToggle">
                    <input type="checkbox" />

                    <span />
                    <span />
                    <span />

                    <ul className="menu">
                        <NavLink className="letter-button" to="/home" >Home</NavLink>
                        <NavLink className="letter-button" to="/our-accounts">Our Accounts</NavLink>
                        <NavLink className="letter-button" to="/services">Services</NavLink>
                        <NavLink className="letter-button" to="/request-service">Request Service</NavLink>
                        <NavLink className="letter-button" to="/apply">Apply</NavLink>
                        <NavLink className="letter-button" to="/about">About</NavLink>
                        <NavLink className="letter-button" to="/contact">Contact</NavLink>
                    </ul>
                </div>
            </nav>
        );
    }
}