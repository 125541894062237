import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.css';

import Home from './Home';
import Services from "./components/services"
import reportWebVitals from './reportWebVitals';
import RequestService from './components/requestService';
import Apply from './components/apply';
import About from './components/about';
import Contact from './components/contact';
import OurAccounts from './components/ourAccounts';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ParallaxProvider>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/our-accounts' element={<OurAccounts />} />
          <Route path="/services" element={<Services />} />
          <Route path="/request-service" element={<RequestService />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

        </Routes>
      </Router>
    </React.StrictMode>
  </ParallaxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
