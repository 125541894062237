import { Component } from "react"
import React from 'react';

import { NavLink } from "react-router-dom";
import "../styles/navbar.css"
import { FaPhone, FaMapMarkerAlt } from "react-icons/fa"
import Hamburger from "./hamburger";



export default class Navbar extends Component {

    state = {
        isLoading: true,
    };

    render() {
        return (
            <nav className="hamburger-background-container">
                <Hamburger />

                <div className="nav-container">
                    <div className="nav-left">
                        <FaMapMarkerAlt className="map-icon" />
                    <p>Reno, NV</p>
                </div>
                    <div className="nav-center">
                        <div className="header">

                            <h2>Nevada Exhaust</h2>
                            <h2>Cleaning</h2>
                        </div>

                        <div className="links-container">
                            <NavLink to="/home" >Home</NavLink>
                            <NavLink to="/our-accounts" >Our Accounts</NavLink>
                            <NavLink to="/services">Services</NavLink>
                            <NavLink to="/request-service">Request Service</NavLink>
                            <NavLink to="/apply">Apply</NavLink>
                            <NavLink to="/about">About</NavLink>
                            <NavLink to="/contact">Contact</NavLink>
                        </div>
                    </div>
                    <div className="nav-right">
                        <FaPhone className="phone-icon" />

                        <a href="tel:775-355-9915">(775) 355-9915</a>
                    </div>
                </div>
            </nav>
        )
    }


}