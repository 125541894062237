import { Component } from "react";
import React from 'react';

import '../styles/footer.css';
import 'font-awesome/css/font-awesome.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockFour } from '@fortawesome/free-solid-svg-icons'
import { FaFacebookSquare, FaPhone, FaBlackTie, FaMapMarkerAlt } from "react-icons/fa"


export default class Footer extends Component {

    state = {
        stamps: [],
        id: null,
        date: "",
        time: "",
        payDay: "",
        isPayDay: false,
        clocked_in: false,
        // status: [],
        calendar: false,
        isLoading: true,
    };

    render() {
        return (
            <footer className="footer-container">
                <div className="footer-main">

                    <div className="address">
                        <FaMapMarkerAlt className="map-icon" />
                        940 Matley Lane
                        Suite 17
                        Reno , NV 89502
                    </div>
                    <div className="footer-phone">
                        <FaPhone className="phone-icon" />
                        <a href="tel:775-355-9915">(775) 355-9915</a>

                    </div>
                    <div className="footer-image">
                        <img src="../images/nevada-white.png" alt="Logo" />
                    </div>
                    <div className="careers">
                        <FaBlackTie className="careers-icon" />
                        <a href="/apply">Careers</a>
                    </div>
                    <div className="available">
                        <FontAwesomeIcon className="clock-icon" icon={faClockFour} />

                        24-hour emergency service available!
                    </div>
                </div>
                <div className="social-media">

                    <a className="facebook-icon" target="_blank" rel="noreferrer" href="https://www.facebook.com/Nevada-Exhaust-Cleaning-1722137008006718">
                        <FaFacebookSquare />
                    </a>
                </div>
                <div className="copy-rights">
                    <div>
                        © 2022 KST Development | All rights reserved
                    </div>
                </div>
            </footer>
        )
    }


}