// import React from "react";
// import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import "../styles/slides.css";

// class Slides extends React.Component {
//     render() {
//         const slides = [
//             {
//                 imageSrc: "../../images/before_afters/ex-fan-before.jpeg",
//                 altText: "dirty exhaust fan",
//                 title: "Exhaust Fan",
//                 caption: "Before"
//             },
//             {
//                 imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
//                 altText: "clean exhaust fan",
//                 title: "Exhaust Fan",
//                 caption: "After"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0588.jpg",
//                 altText: "dirty grease duct",
//                 title: "Grease Duct",
//                 caption: "Before"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0589.jpg",
//                 altText: "clean grease duct",
//                 title: "Grease Duct",
//                 caption: "After"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0592.jpg",
//                 altText: "dirty damper door",
//                 title: "Damper Door",
//                 caption: "Before"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0593.jpg",
//                 altText: "clean damper door",
//                 title: "Damper Door",
//                 caption: "After"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0600.jpg",
//                 altText: "dirty hood",
//                 title: "Hood",
//                 caption: "Before"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0601.jpg",
//                 altText: "clean hood",
//                 title: "Hood",
//                 caption: "After"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0598.jpg",
//                 altText: "dirty exhaust fan",
//                 title: "Exhaust Fan",
//                 caption: "Before"
//             },
//             {
//                 imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
//                 altText: "clean exhaust fan",
//                 title: "Exhaust Fan",
//                 caption: "After"
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0595.jpg",
//                 altText: "exhaust fan on roof restaurant clean service",
//                 title: "Exhaust Fan on Roof",
//                 caption: ""
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0596.jpg",
//                 altText: "exhaust fan on roof restaurant clean service",
//                 title: "Exhaust Fan",
//                 caption: ""
//             },
//             {
//                 imageSrc: "../../images/before_afters/IMG_0597.jpg",
//                 altText: "clean hood clean commercial kitchen",
//                 title: "Clean Commercial Kitchen",
//                 caption: ""
//             }
//         ];


//         return (
//             <div className='container-fluid'>
//                 <div className="row">
//                     <div className="col-12">
//                         <Carousel fade slide={false}>
//                             {slides.map((slide, index) => (
//                                 <Carousel.Item key={index}>
//                                     <img
//                                         className="d-block w-100"
//                                         src={slide.imageSrc}
//                                         alt={slide.altText}
//                                     />
//                                     <Carousel.Caption>
//                                         <h3>{slide.title}</h3>
//                                         <p>{slide.caption}</p>
//                                     </Carousel.Caption>
//                                 </Carousel.Item>
//                             ))}
//                         </Carousel>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default Slides;

// const slides = [
//     {
//         imageSrc: "../../images/before_afters/ex-fan-before.jpeg",
//         altText: "dirty exhaust fan",
//         title: "Exhaust Fan",
//         caption: "Before"
//     },
//     {
//         imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
//         altText: "clean exhaust fan",
//         title: "Exhaust Fan",
//         caption: "After"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0588.jpg",
//         altText: "dirty grease duct",
//         title: "Grease Duct",
//         caption: "Before"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0589.jpg",
//         altText: "clean grease duct",
//         title: "Grease Duct",
//         caption: "After"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0592.jpg",
//         altText: "dirty damper door",
//         title: "Damper Door",
//         caption: "Before"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0593.jpg",
//         altText: "clean damper door",
//         title: "Damper Door",
//         caption: "After"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0600.jpg",
//         altText: "dirty hood",
//         title: "Hood",
//         caption: "Before"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0601.jpg",
//         altText: "clean hood",
//         title: "Hood",
//         caption: "After"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0598.jpg",
//         altText: "dirty exhaust fan",
//         title: "Exhaust Fan",
//         caption: "Before"
//     },
//     {
//         imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
//         altText: "clean exhaust fan",
//         title: "Exhaust Fan",
//         caption: "After"
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0595.jpg",
//         altText: "exhaust fan on roof restaurant clean service",
//         title: "Exhaust Fan on Roof",
//         caption: ""
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0596.jpg",
//         altText: "exhaust fan on roof restaurant clean service",
//         title: "Exhaust Fan",
//         caption: ""
//     },
//     {
//         imageSrc: "../../images/before_afters/IMG_0597.jpg",
//         altText: "clean hood clean commercial kitchen",
//         title: "Clean Commercial Kitchen",
//         caption: ""
//     }
// ];

import React from "react";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import "../styles/slides.css";

const slides = [
    {
        imageSrc: "../../images/before_afters/ex-fan-before.jpeg",
        altText: "dirty exhaust fan",
        title: "Exhaust Fan",
        caption: "Before"
    },
    {
        imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
        altText: "clean exhaust fan",
        title: "Exhaust Fan",
        caption: "After"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0588.jpg",
        altText: "dirty grease duct",
        title: "Grease Duct",
        caption: "Before"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0589.jpg",
        altText: "clean grease duct",
        title: "Grease Duct",
        caption: "After"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0592.jpg",
        altText: "dirty damper door",
        title: "Damper Door",
        caption: "Before"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0593.jpg",
        altText: "clean damper door",
        title: "Damper Door",
        caption: "After"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0600.jpg",
        altText: "dirty hood",
        title: "Hood",
        caption: "Before"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0601.jpg",
        altText: "clean hood",
        title: "Hood",
        caption: "After"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0598.jpg",
        altText: "dirty exhaust fan",
        title: "Exhaust Fan",
        caption: "Before"
    },
    {
        imageSrc: "../../images/before_afters/ex-fan-after.jpeg",
        altText: "clean exhaust fan",
        title: "Exhaust Fan",
        caption: "After"
    },
    {
        imageSrc: "../../images/before_afters/IMG_0595.jpg",
        altText: "exhaust fan on roof restaurant clean service",
        title: "Exhaust Fan on Roof",
        caption: ""
    },
    {
        imageSrc: "../../images/before_afters/IMG_0596.jpg",
        altText: "exhaust fan on roof restaurant clean service",
        title: "Exhaust Fan",
        caption: ""
    },
    {
        imageSrc: "../../images/before_afters/IMG_0597.jpg",
        altText: "clean hood clean commercial kitchen",
        title: "Clean Commercial Kitchen",
        caption: ""
    }
];

class Slides extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  componentDidMount() {
    this.startSlideshow();
  }

  componentWillUnmount() {
    this.stopSlideshow();
  }

  startSlideshow = () => {
    this.slideshowInterval = setInterval(() => {
      const { activeIndex } = this.state;
      this.setState({
        activeIndex: (activeIndex + 1) % slides.length
      });
    }, 3000);
  };

  stopSlideshow = () => {
    clearInterval(this.slideshowInterval);
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div className='container-fluid'>
        <div className="row">
          <div className="col-12">
            <Carousel fade slide={false} activeIndex={activeIndex} onSelect={() => {}} className="fade-carousel">
              {slides.map((slide, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="slide-image"
                    src={slide.imageSrc}
                    alt={slide.altText}
                  />
                  <Carousel.Caption>
                    <h3>{slide.title}</h3>
                    <p>{slide.caption}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

export default Slides;
