import { Component } from "react"
import React from 'react';

import emailjs from "@emailjs/browser"
import Swal from "sweetalert2"
import Iframe from "react-iframe"
import Navbar from "./navbar"
import Footer from "./footer"
import { FaFacebookSquare, FaPhone, FaMapMarkerAlt, FaClock, FaEnvelope } from "react-icons/fa"


import "../styles/contact.css"


export default class Contact extends Component {

    state = {
        isLoading: true,
    };

    sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6j67ki6', 'template_i9hg1z9', e.target, 'user_WpLvYUKh59KgaFazMjV56')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
        this.showAlert()
    };

    showAlert = () => {
        Swal.fire({
            title: 'Message Sent',
            text: "Your message was successfully sent to Nevada Exhaust Cleaning!",
            type: 'success',
        })
    }

    render() {
        return (
            <div className="contact-container">

                <Navbar />
                <div className="contact-page-container">
                    <section className="contact-contact-box">
                        <header>
                            <h3>Contact Us</h3>
                        </header>
                        <div className="contact-details-container">
                            <div className="contact-main">

                                <div className="contact-left">
                                    <FaMapMarkerAlt />
                                    <a href="#map">
                                        <p> Nevada Exhaust Cleaning</p>
                                        <p>940 Matley Lane</p>
                                        <p>Suite 17</p>
                                    </a>
                                    <p>
                                        <FaPhone />
                                        <a href="tel:775-355-9915">(775) 355-9915</a>
                                    </p>

                                </div>

                                <div className="contact-right">

                                    <p><FaEnvelope /> <a href="#message"> office@nvexhaust.com </a></p>

                                    <p><FaClock /> <a href="/request-service/">24-hour emergency service available!</a> </p>

                                </div>
                            </div>

                            <div className="contact-social-links">
                                <a className="facebook-icon facebook-icon-contact" target="_blank" rel="noreferrer" href="https://www.facebook.com/Nevada-Exhaust-Cleaning-1722137008006718">
                                    <FaFacebookSquare />
                                </a>
                            </div>

                        </div>
                    </section>

                    <div className="contact-page-message-box">
                        <div className="email-image">
                            <img src="../../images/nevada-white.png" alt="nevada exhaust cleaning logo png" />
                        </div>
                        <form onSubmit={this.sendEmail}>
                            <div className="email-header">
                                <header>
                                    <h2 className="contact-message">Send a message</h2>
                                </header>

                            </div>
                            <div className="top-inputs">
                                <input className="input" placeholder="Name" type="text" name="from_name" />
                                <input className="input" placeholder="Phone" type="text" name="phone" />
                                <input className="input" placeholder="Email" type="email" name="email" />
                                <input className="input" placeholder="Company Name" type="text" name="company" />

                            </div>

                            <div className="bottom-inputs">
                                <textarea placeholder="Message" name="message" />
                                <button className="button" type="submit" value={this.state.sendStatus} >Send</button>
                            </div>

                        </form>
                    </div>

                    <section className="map-container">
                        <Iframe
                            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3077.9091052285703!2d-119.78203268450005!3d39.516541117815244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80993f679b51839f%3A0xdc31fb3adb609d4e!2s940%20Matley%20Ln%20%23%2017%2C%20Reno%2C%20NV%2089502!5e0!3m2!1sen!2sus!4v1652388883157!5m2!1sen!2sus"
                            // width="450px"
                            // height="450px"
                            className="map"
                            display="initial"
                            position="relative"
                            id="map"
                        />
                    </section>
                </div>
                <Footer />
            </div>
        )
    }


}