import './styles/home.css';
import Navbar from "./components/navbar"
import Footer from "./components/footer"
import Slides from "./components/slides"
import { ParallaxBanner } from 'react-scroll-parallax';
// import Image3D from './components/image3d';


function App() {
  return (

    <div className="app-container">

      <Navbar />

      <div className="home-page-container">
        <div className="featured-container" style={{ overflow: "hidden" }}>

          <svg
            preserveAspectRatio="none"
            viewBox="0 0 1200 120"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: '#003366', width: '118%', height: 97, }}
          >
            <path
              d="M0 0v46.29c47.79 22.2 103.59 32.17 158 28 70.36-5.37 136.33-33.31 206.8-37.5 73.84-4.36 147.54 16.88 218.2 35.26 69.27 18 138.3 24.88 209.4 13.08 36.15-6 69.85-17.84 104.45-29.34C989.49 25 1113-14.29 1200 52.47V0z"
              opacity=".25"
            />
            <path
              d="M0 0v15.81c13 21.11 27.64 41.05 47.69 56.24C99.41 111.27 165 111 224.58 91.58c31.15-10.15 60.09-26.07 89.67-39.8 40.92-19 84.73-46 130.83-49.67 36.26-2.85 70.9 9.42 98.6 31.56 31.77 25.39 62.32 62 103.63 73 40.44 10.79 81.35-6.69 119.13-24.28s75.16-39 116.92-43.05c59.73-5.85 113.28 22.88 168.9 38.84 30.2 8.66 59 6.17 87.09-7.5 22.43-10.89 48-26.93 60.65-49.24V0z"
              opacity=".5"
            />
            <path d="M0 0v5.63C149.93 59 314.09 71.32 475.83 42.57c43-7.64 84.23-20.12 127.61-26.46 59-8.63 112.48 12.24 165.56 35.4C827.93 77.22 886 95.24 951.2 90c86.53-7 172.46-45.71 248.8-84.81V0z" />

          </svg>
          <div className="featured-items">
            <div className="feature-left">
              <div className="nevada-card">
                <img src="../../images/nevada-white.png" alt="nec-logo" />
              </div>
            </div>
            <div className="feature-right">
              <header>
                <h2 className="serving">Now serving Restaurant Hood Exhaust Systems!</h2>
              </header>
              <header>
                <h2>24-hour emergency service available!</h2>
              </header>
              <article>
                <p>Professional Exhaust and Duct Cleaning Services in Nevada
                  Protect your restaurant, cafeteria, or commercial kitchen by having your hoods and appliances professionally cleaned by Nevada Exhaust Cleaning. Founded in 1989, we have over 30 years’ experience in cleaning restaurant hoods. Breathe easy as all our cleaning services are backed by a full guarantee. What’s more! We are certified with the Nevada State Fire Marshal and NFPA 96. <span className="hyperlink"><a className="contact-us" href="tel:775-355-9915">Contact us</a></span>  to get a FREE estimate!</p>
                <a className="services" href="/request-service">Request Service</a>
              </article>
            </div>

          </div>
        </div>

{/* <Image3D imagePath={'./images/nevada-white.png'} width={800} height={600} /> */}
        <div className="featured-image">

          <ParallaxBanner
            className='banner'
            layers={[
              {
                children: <img alt="nevada exhaust cleaning staff people walking hard hats construction jay" src="../../images/staff.jpeg" />,
                expanded: true,
                speed: -20,
                scale: [1, 0.9], // Adjust the second value (vertical scale) to reduce stretching
                opacity: [0.9, 1],
                easing: 'easeInOutQuad',
              },
              {
                children: <h1 className='over-text-header'>Real people. Real service.</h1>,
              },

            ]}
          />
        </div>

        <section className="square-container">
          <div className="squares">
            <div className="square-row row-one">
              <div className="image-square one">
                {/* image here */}
              </div>
              <article className="text-square">
                <header>
                  <h1>Restaurant Hood Cleaning</h1>
                </header>
                <p>Keep your restaurant completely safe for your employees and clients. Eliminate fire hazards from your kitchen’s cooking systems with our affordable kitchen exhaust cleaning services. Our expert professionals will provide complete cleaning, inspection, and repair services for your full hood and duct system. <span className="hyperlink"><a className="contact-us" href="tel:775-355-9915">Call us</a></span>  today!</p>
                <a className="services white-button" href="/services">More Details</a>

              </article>
            </div>
            <div className="square-row row-two">
              <article className="text-square">
                <header>
                  <h1>Commercial Laundry Duct</h1>
                </header>
                <p>Count on the experts at Nevada Exhaust Cleaning to keep your laundry ducts clean and its location safe every day. Our technicians are certified with the Nevada State Fire Marshal and NFPA 96. <span className="hyperlink"><a className="contact-us" href="tel:775-355-9915">Call us</a></span> today to schedule an appointment! We'll get back to you by the next business day.</p>

                <a className="services white-button" href="/services">More Details</a>

              </article>
              <div className="image-square two">
                {/* image here */}
              </div>
            </div>

          </div>
        </section>

      </div>
      <div className="slide-container">
        <Slides />
      </div>
      <div className="request-section">
        <h3>Need to request a service?</h3>
        <a className="services" href="/request-service/">Request Service</a>

      </div>

      <div className="footer">

        <Footer />
      </div>
    </div>
  );
}

export default App;
