import React from "react";
import emailjs from "@emailjs/browser"
import Swal from "sweetalert2"

import Navbar from "./navbar"
import Footer from "./footer"
import "../styles/requestService.css";



export default class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendStatus: "Send"
        };
    }

    sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6j67ki6', 'template_7iak9s8', e.target, 'user_WpLvYUKh59KgaFazMjV56')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
        this.showAlert()
    };

    showAlert = () => {
        Swal.fire({
            title: 'Message Sent',
            text: "Your message was successfully sent to Nevada Exhaust Cleaning!",
            type: 'success',
        })
    }

    render() {
        return (
            <div className="request-service-page">

                <Navbar />
                <div className="contact-box">
                    <div className="service-headers">
                        <header>
                            <h2 data-shadow='dang!'>Request a Service</h2>
                        </header>
                        <article>
                            <h4>Fill out this short form and a Nevada Exhaust Cleaning representative will contact you within the next business day. If you need immediate assistance, please call:</h4>
                        </article>
                        <a href="tel:775-355-9915">(775) 355-9915</a>

                    </div>
                    <section className="box">

                        <form onSubmit={this.sendEmail}>
                            <div className="top-inputs">
                                <div className="input-container">
                                    <input className="input" placeholder="Name" type="text" name="from_name" />
                                </div>

                                <div className="input-container">
                                    <input className="input" placeholder="Phone" type="text" name="phone" />
                                </div>
                                <div className="input-container">
                                    <input className="input" placeholder="Email" type="email" name="email" />
                                </div>
                                <div className="input-container">

                                    <select name="service_choice" id="service_choice">
                                        <option value="'no specific service chosen'">Select Service...</option>
                                        <option value="Restaurant Hood Cleaning">Restaurant Hood Cleaning</option>
                                        <option value="Commercial Laundry Duct">Commercial Laundry Duct</option>
                                        <option value="Fire Cleanup">Fire Cleanup</option>
                                        <option value="'no specific service chosen'">Other</option>

                                    </select>
                                </div>
                            </div>

                            <div className="bottom-inputs">
                                <input className="business-input" placeholder="Business Name" type="text" name="company" />
                                <input className="business-input" placeholder="Address needing service" type="text" name="address" />

                                <textarea placeholder="Message" name="message" />
                                <button className="button" type="submit" value={this.state.sendStatus} >Send</button>
                            </div>

                        </form>
                    </section>
                </div>
                <Footer />
            </div>

        );
    }
}